import { FC, ChangeEvent } from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  FormControl,
  FormLabel,
  Input, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { Autocomplete, Option } from 'chakra-ui-simple-autocomplete';
import { useGlobal } from "../GlobalContext";
import { fetcher } from "../libs/backend";
import useSWR from "swr";
import { useState, useEffect, useRef } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getDjangoCookies } from "../utils";
import MatchTypeFilter from "./MatchTypeFilter";
import EventTypeFilter from "./EventTypeFilter";
import SeasonFilter from "./SeasonFilter";

interface Player {
  user: number;
  first_name: string;
  last_name: string;
  language: string;
}

interface Event {
  event_id: number;
  event_name: string;
}

interface Match {
  event: number;
  date: string;
  match_type: string;
  team_1_player_1: string | null;
  team_1_player_2: string | null;
  team_2_player_1: string | null;
  team_2_player_2: string | null;
  score_team_1: number;
  score_team_2: number;
}


/**
 * Button which opens a new modal to record new match.
 */
const RecordNewMatchButton: FC = () => {
  const cookies = getDjangoCookies();

  const { state: { current_group_id, current_season_id } } = useGlobal();
  const { data: eventsData, error: eventsError } = useSWR<Event[]>(`api/group/${current_group_id}/events/`, fetcher);
  const { data: playersData, error: playersError } = useSWR<Player[]>(`api/group/${current_group_id}/players/`, fetcher);
  const cancelRef = useRef<HTMLElement | null>(null);

  // Set the default CSRF token in Axios headers
  let csrftoken = '';
  if (cookies.csrftoken) {
    csrftoken = cookies.csrftoken;
  }
  axios.defaults.headers.common['X-CSRFToken'] = csrftoken;

  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>(undefined);
  const [selectedSeason, setSelectedSeason] = useState<number | undefined>(current_season_id);
  const [matchType, setMatchType] = useState<string>('P');
  const [date, setDate] = useState<string>('');
  const [team1Options, setTeam1Options] = useState<Option[]>([]);
  const [team2Options, setTeam2Options] = useState<Option[]>([]);
  const [scoreTeam1, setScoreTeam1] = useState<number>(0);
  const [scoreTeam2, setScoreTeam2] = useState<number>(0);
  const [availablePlayers, setAvailablePlayers] = useState<Option[]>([]);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    if (playersData) {
      const playersOptions:any = [];
      playersData.forEach((item:any) => {
        let name = item.language === 'KOR'? item.last_name+item.first_name : item.first_name+' '+item.last_name;
        playersOptions.push({value: item.user, label: name})
      })
      setAvailablePlayers([...availablePlayers, ...playersOptions])
    }
  }, [playersData]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()

/*   const handleSelect = (value) => {
    setTeam1Options(value);
  }; */
  const handleAlert = () => {
    setShowAlert(true);
  }

  const handleCloseAlert = () => {
    setShowAlert(false);
  }


  const closeModal = () => {
    setMatchType('P');
    setDate('');
    setTeam1Options([]);
    setTeam2Options([]);
    setScoreTeam1(0);
    setScoreTeam2(0);
    setShowAlert(false);
    onClose();
  }

  return (
    <>
      <Button colorScheme='green' size='sm' onClick={onOpen}>
        Record new match
      </Button>
      <Modal isOpen={isOpen} onClose={handleAlert}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add a new match</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Season</FormLabel>
              <SeasonFilter
                selectedSeason={selectedSeason}
                onSelectChangeSeason={(e: ChangeEvent<HTMLSelectElement>) => setSelectedSeason(Number(e.target.value))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Match type</FormLabel>
              <MatchTypeFilter fromDashBoard={false} onSelectChangeMatch={(e: ChangeEvent<HTMLSelectElement>) => setMatchType(e.target.value)}/>
            </FormControl>
            <FormControl>
              <FormLabel>Date</FormLabel>
              <Input id="date" type="date" onChange={(e: ChangeEvent<HTMLInputElement>) => setDate(e.target.value)} />
            </FormControl>
            <FormControl>
              <FormLabel>Event</FormLabel>
              <EventTypeFilter onSelectChange={e => setSelectedEvent(eventsData?.find((event: Event) => event.event_id === Number(e.target.value)))} hideAll={true}/>
            </FormControl>
            <FormControl>
              <FormLabel>Team 1</FormLabel>
              <Autocomplete
                options={availablePlayers}
                result={team1Options}
                setResult={(availablePlayers: Option[]) => setTeam1Options(availablePlayers)}
                placeholder={'Start typing player name(s) for the Team 1 (e.g. 홍길동)'}
                allowCreation={false}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Team 2</FormLabel>
              <Autocomplete
                options={availablePlayers}
                result={team2Options}
                setResult={(availablePlayers: Option[]) => setTeam2Options(availablePlayers)}
                placeholder={'Start typing player name(s) for the Team 2 (e.g. 홍길동)'}
                allowCreation={false}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Score Team 1</FormLabel>
              <Input
                id="score_team_1"
                type="number"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setScoreTeam1(Number(e.target.value))}
                _placeholder={{
                  color: 'gray.500',
                }}
                placeholder='0'
              />
            </FormControl>
            <FormControl>
              <FormLabel>Score Team 2</FormLabel>
              <Input
                id="score_team_2"
                type="number"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setScoreTeam2(Number(e.target.value))}
                _placeholder={{
                  color: 'gray.500',
                }}
                placeholder='0'
              />
            </FormControl>
          </ModalBody>
          <ModalFooter mt={6}>
            <Button mr={6} variant={'ghost'} onClick={handleAlert}>
              Cancel
            </Button>
            <Button
              colorScheme='teal'
              disabled={submitted}
              onClick={(e) => {
                setSubmitted(true);
                // Gather your match data here
                 let error_found = false

                if (!selectedEvent) {
                  toast({
                    title: "An error occurred.",
                    description: "Please choose event type",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });

                  error_found = true;
                }

                if (matchType === 'S' && (team1Options.length !== 1 || team2Options.length !== 1)) {
                  toast({
                    title: "An error occurred.",
                    description: "Please choose 2 players",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });

                  error_found = true;
                } else if (matchType === 'S' && (team1Options.length && team2Options) ) {
                    const singlePlayers = new Set([team1Options[0].value, team2Options[0].value]);
                    if (singlePlayers.size !== 2) {
                      error_found = true;
                      toast({
                        title: "An error occurred.",
                        description: "Each player need to be unique",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                    } 

                } 

                if (date === '') {
                  toast({
                    title: "An error occurred.",
                    description: "Please choose a date",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });

                  error_found = true;
                }

                if (matchType === 'D' && !(team1Options.length === 2 && team2Options.length === 2)) {
                  toast({
                    title: "An error occurred.",
                    description: "Please choose all 4 players",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });

                  error_found = true;
                } else if (matchType === 'D' && team1Options.length == 2 && team2Options.length == 2) {
                    const doublePlayers = new Set([team1Options[0].value, team1Options[1].value, team2Options[0].value, team2Options[1].value]);
                    if (doublePlayers.size !== 4) {
                      error_found = true;
                      toast({
                        title: "An error occurred.",
                        description: "Each player need to be unique",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                    }

                }
                if (!error_found) {
                  const matchData: Match = {
                    event: selectedEvent!.event_id!,
                    date: date,
                    match_type: matchType,
                    team_1_player_1: team1Options[0].value,
                    team_1_player_2: team1Options.length == 2? team1Options[1].value : null,
                    team_2_player_1: team2Options[0].value,
                    team_2_player_2: team2Options.length == 2? team2Options[1].value : null,
                    score_team_1: scoreTeam1,
                    score_team_2: scoreTeam2,
                  };
                  axios.post(`api/submit_match_result/`, matchData)
                    .then((response: AxiosResponse) => {
                      if (response.status === 201) {
                        toast({
                          title: "Match recorded",
                          description: "The match has been successfully recorded!",
                          status: "success",
                          duration: 3000,
                          isClosable: true,
                        });
                        setMatchType('P');
                        setDate('');
                        setTeam1Options([]);
                        setTeam2Options([]);
                        setScoreTeam1(0);
                        setScoreTeam2(0);
                        onClose();
                      } else {
                        toast({
                          title: "An error occurred.",
                          description: "Unable to record the match.",
                          status: "error",
                          duration: 3000,
                          isClosable: true,
                        });
                      }
                    })
                    .catch((error) => {
                      toast({
                        title: "An error occurred.",
                        description: "Unable to record the match.",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                    });
                } else {
                  setSubmitted(false);
                }

              }}
            >
              Record match
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog isOpen={showAlert} onClose={handleCloseAlert} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Add a new match</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to discard the change?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={handleCloseAlert}>Cancel</Button>
            <Button colorScheme="red" onClick={closeModal} ml={3}>Confirm</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      </>
  );
}

export default RecordNewMatchButton;
